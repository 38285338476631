<template>
  <div v-if="isInitialTopicsLoaded">
    <template v-if="hasTopics">
      <div
        class="d-flex align-center mb-2"
        :class="{ 'flex-column-reverse': $vuetify.breakpoint.smAndDown }"
      >
        <v-select
          v-if="$vuetify.breakpoint.xs"
          v-model="activeTab"
          :items="[{
            text: $t('topics.list.tabs.active'),
            value: TAB_ACTIVE
          }, {
            text: $t('topics.list.tabs.archived'),
            value: TAB_ARCHIVED
          }]"
          @change="resetView"
        />

        <v-tabs
          v-else
          v-model="activeTab"
          background-color="transparent"
          @change="resetView"
        >
          <v-tab>
            {{ $t('topics.list.tabs.active') }}
          </v-tab>
          <v-tab>
            {{ $t('topics.list.tabs.archived') }}
          </v-tab>
        </v-tabs>

        <Permission permission="topic:write">
          <div
            class="d-flex"
            :class="{
              'flex-column': $vuetify.breakpoint.xs,
              'mb-6': $vuetify.breakpoint.smAndDown
            }"
          >
            <v-btn
              color="primary"
              :loading="isPerformingAction('generatingTopic')"
              :disabled="isPerformingAction('generatingTopic', true)"
              @click="generateTopic"
            >
              <v-icon left>
                mdi-auto-fix
              </v-icon>
              {{ $t('topics.actions.generate') }}
            </v-btn>

            <v-btn
              color="primary"
              :class="{
                'mx-4 mr-2': $vuetify.breakpoint.smAndUp,
                'mt-2': $vuetify.breakpoint.xs
              }"
              :disabled="isPerformingAction('creatingTopic', true)"
              @click="startCreateTopic"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              {{ $t('topics.actions.create') }}
            </v-btn>
          </div>
        </Permission>
      </div>

      <v-tabs-items
        v-model="activeTab"
        class="py-4 transparent"
      >
        <v-tab-item>
          <v-scroll-y-transition group>
            <!-- Errors -->
            <v-row
              v-if="error"
              :key="error"
            >
              <v-col>
                <MaxActiveTopicsReachedAlert
                  v-if="error === 'MAX_ACTIVE_TOPICS_REACHED'"
                  :max-active-topics="maxActiveTopics"
                  @dismiss="resetError"
                />
                <GenericErrorAlert
                  v-else
                  :error="error"
                  @dismiss="resetError"
                />
              </v-col>
            </v-row>

            <!-- New topic card -->
            <v-row
              v-if="showCreateTopic"
              key="new-topic"
            >
              <v-col>
                <TopicCard
                  is-new
                  @update="updateTopicInCache"
                  @cancel="cancelCreateTopic"
                  @create="addTopicToCache"
                  @error="handleError"
                />
              </v-col>
            </v-row>

            <!-- Active topics -->
            <v-row
              v-for="topic in activeTopics"
              :key="topic.id"
            >
              <v-col>
                <TopicCardAnimated
                  v-bind="topic"
                  @update="updateTopicInCache"
                  @error="handleError"
                />
              </v-col>
            </v-row>
          </v-scroll-y-transition>
        </v-tab-item>

        <v-tab-item>
          <BlurredBackdropUpselling
            :is-blurred="!mayUnarchiveTopics"
          >
            <v-scroll-y-transition group>
              <!-- Errors -->
              <v-row
                v-if="error"
                :key="error"
              >
                <v-col>
                  <MaxActiveTopicsReachedAlert
                    v-if="error === 'MAX_ACTIVE_TOPICS_REACHED'"
                    :max-active-topics="maxActiveTopics"
                    @dismiss="resetError"
                  />
                  <GenericErrorAlert
                    v-else
                    :error="error"
                    @dismiss="resetError"
                  />
                </v-col>
              </v-row>

              <!-- Archived topics -->
              <v-row
                v-for="topic in archivedTopics"
                :key="topic.id"
              >
                <v-col>
                  <TopicCard
                    v-bind="topic"
                    @update="updateTopicInCache"
                    @error="handleError"
                  />
                </v-col>
              </v-row>
            </v-scroll-y-transition>
          </BlurredBackdropUpselling>
        </v-tab-item>
      </v-tabs-items>

      <v-row>
        <v-col class="pa-8">
          {{ $t('topics.limit-note.line1.0') }} <strong>{{ activeTopics.length }}</strong> {{ $t('topics.limit-note.line1.1') }}<br>
          {{ $t('topics.limit-note.line2.0') }} <strong>{{ maxActiveTopics }}</strong> {{ $t('topics.limit-note.line2.1') }}<br>
          {{ $t('topics.limit-note.line3.0') }}
          <router-link :to="{ name: 'Settings', params: { section: 'contract' } }">
            {{ $t('topics.limit-note.line3.1') }}
          </router-link>
        </v-col>
      </v-row>
    </template>

    <div
      v-else
      class="d-flex justify-center align-center flex-column"
      :class="{
        'py-6': $vuetify.breakpoint.xs,
        'py-16': $vuetify.breakpoint.smAndUp
      }"
    >
      <div class="welcome-box">
        <p class="text--secondary">
          {{ $t('topics.welcome.p1', ) }}
        </p>
        <p class="text--secondary">
          {{ $t('topics.welcome.p2', ) }}
        </p>
        <v-btn
          x-large
          color="primary"
          class="mt-10"
          :loading="isPerformingAction('generatingInitialTopics')"
          @click="generateInitialTopics"
        >
          <v-icon left>
            mdi-auto-fix
          </v-icon>
          {{ $t('topics.welcome.cta') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import bus, { eventNames } from '@/lib/eventBus'
import featureMixin from '@/mixins/feature'
import performingActionMixin from '@/mixins/performingAction'
import Permission from '@/components/Permission'
import BlurredBackdropUpselling from '@/components/BlurredBackdropUpselling'
import GET_TOPICS from './graphql/getTopics.gql'
import GENERATE_TOPIC from './graphql/generateTopic.gql'
import GENERATE_INITIAL_TOPICS from './graphql/generateInitialTopics.gql'
import TopicStatus from './TopicStatus'
import TopicCard from './TopicCard'
import TopicCardAnimated from './TopicCardAnimated'
import GenericErrorAlert from './GenericErrorAlert'
import MaxActiveTopicsReachedAlert from './MaxActiveTopicsReachedAlert'

// Tab indexes
// Didn't use # routes as it didn't really work switching tabs programmatically.
const TAB_ACTIVE = 0
const TAB_ARCHIVED = 1

export default {
  components: {
    TopicCard,
    TopicCardAnimated,
    BlurredBackdropUpselling,
    Permission,
    GenericErrorAlert,
    MaxActiveTopicsReachedAlert
  },

  mixins: [
    featureMixin,
    performingActionMixin
  ],

  data: () => ({
    TAB_ACTIVE,
    TAB_ARCHIVED,
    activeTab: TAB_ACTIVE,
    topics: [],
    showCreateTopic: false,
    isInitialTopicsLoaded: false,
    error: null
  }),

  apollo: {
    topics: {
      query: GET_TOPICS,
      result ({ loading }) {
        if (!loading) {
          this.isInitialTopicsLoaded = true
        }
      },
      cachePolicy: 'cache-and-network'
    }
  },

  computed: {
    config () {
      return this.getFeature('topic').config
    },

    maxActiveTopics () {
      return this.config.maxActiveTopics
    },

    mayUnarchiveTopics () {
      return this.config.mayUnarchiveTopics
    },

    activeTopics () {
      return this.topics.filter(topic => topic.status !== TopicStatus.ARCHIVED)
    },

    archivedTopics () {
      const filteredTopics = this.topics.filter(topic => topic.status === TopicStatus.ARCHIVED)

      if (!this.mayUnarchiveTopics) {
        return filteredTopics.slice(0, 3)
      }

      return filteredTopics
    },

    hasTopics () {
      return this.topics.length > 0
    }
  },

  methods: {
    /**
     * Show the create topic form.
     */
    startCreateTopic () {
      this.activeTab = TAB_ACTIVE
      this.resetError()
      this.$nextTick(() => {
        this.showCreateTopic = true
      })
    },

    /**
     * Hide the create topic form.
     */
    cancelCreateTopic () {
      this.showCreateTopic = false
    },

    /**
     * Is called e.g. when the tab changes and some state should be reset.
     */
    resetView () {
      this.cancelCreateTopic()
      this.resetError()
    },

    async generateTopic () {
      this.$tracking.event('Marketing Topics', 'Clicked', 'Generation')

      this.resetError()

      await this.withPerformingAction('generatingTopic', async () => {
        try {
          await this.$apollo.mutate({
            mutation: GENERATE_TOPIC,
            update: (cache, { data: { topic } }) => {
              const { topics: cachedTopics } = cache.readQuery({ query: GET_TOPICS })
              cache.writeQuery({
                query: GET_TOPICS,
                data: { topics: [topic, ...cachedTopics] }
              })
            }
          })
          this.$tracking.event('Marketing Topics', 'Generation', 'Generation')
        } catch (err) {
          this.handleGraphQLError(err)
        }
      })
    },

    async generateInitialTopics () {
      this.resetError()
      this.$tracking.event('Marketing Topics', 'Clicked', 'Initial Generation')

      await this.withPerformingAction('generatingInitialTopics', async () => {
        try {
          await this.$apollo.mutate({
            mutation: GENERATE_INITIAL_TOPICS,
            update: (cache, { data: { topics } }) => {
              const { topics: cachedTopics } = cache.readQuery({ query: GET_TOPICS })
              cache.writeQuery({
                query: GET_TOPICS,
                data: { topics: [...topics, ...cachedTopics] }
              })
            }
          })
          this.$tracking.event('Marketing Topics', 'Generation', 'Initial Generation')
          bus.$emit(eventNames.TRIGGER_ANIMATION)
        } catch (err) {
          this.handleGraphQLError(err)
        }
      })
    },

    /**
     * Add a new topic to the cache.
     * This method is called as a response to an action on a TopicCard.
     */
    addTopicToCache (topic, cache) {
      this.cancelCreateTopic()

      const { topics: cachedTopics } = cache.readQuery({ query: GET_TOPICS })

      cache.writeQuery({
        query: GET_TOPICS,
        data: { topics: [topic, ...cachedTopics] }
      })
    },

    /**
     * Update the given topic in the cache.
     * This method is called as a response to an action on a TopicCard.
     */
    updateTopicInCache (topic, cache) {
      const { topics: cachedTopics } = cache.readQuery({ query: GET_TOPICS })

      const updatedTopics = cachedTopics.map(cachedTopic =>
        cachedTopic.id === this.id ? topic : cachedTopic
      )

      cache.writeQuery({
        query: GET_TOPICS,
        data: { topics: updatedTopics }
      })
    },

    handleGraphQLError (err) {
      const graphQLError = err.graphQLErrors[0]
      // First we handle specific errors that we expect.
      if (graphQLError?.extensions.code === 'MAX_ACTIVE_TOPICS_REACHED') {
        this.handleError(graphQLError.extensions.code)
      // Otherwise we show the user any message that comes from the server as we cannot further predict the error.
      // It could be a forbidden error though, but that should not arise as long as the frontend is correctly implemented.
      } else {
        this.handleError(err.message)
      }
    },

    handleError (error) {
      this.resetError()
      this.$nextTick(() => {
        this.error = error.replace('GraphQL error: ', '')
      })
    },

    resetError () {
      this.error = null
    }
  }

}
</script>

<style scoped>
.error-text {
  max-width: 800px;
}

.welcome-box {
  max-width: 600px;
  text-align: center;
  font-size: 1.2em;
}
</style>
